<template>
  <div class="w-full min-h-[242px] p-md bg-background-base">
    <ImageBasic
      v-if="props.bannerData.image?.desktop?.url"
      :image-data="props.bannerData.image"
      class="!box-border w-full mb-md px-lg"
    />
    <h4 v-if="props.bannerData.headline !== ''" class="pb-xs">
      {{ t('globalWidgets.notificationLabel') }}
    </h4>
    <Wysiwig
      v-if="props.bannerData.text"
      :content="props.bannerData.text"
      no-container-padding
    />
    <CallToAction
      v-if="ctaData.label"
      :block-data="ctaData"
      class="!text-center md:!text-left !mt-md !mx-0 !mb-0"
      button-class="!rounded-alt-btn !w-full"
    />
  </div>
</template>
<script setup lang="ts">
import type { WidgetNotificationBannerData } from './type';
import ImageBasic from '@/components/components/core/basicImage/image-basic.vue';
import CallToAction from '@/components/block/cta/CallToAction.vue';
import type { CTAData } from '~~/src/components/block/cta/blockTypes';
import { BlockTypeHandle } from '~/components/block/types';
import Wysiwig from '@/components/components/cms/wysiwyg/wysiwyg.vue';

const { t } = useTrans();

const props = defineProps({
  bannerData: {
    type: Object as PropType<WidgetNotificationBannerData>,
    required: true,
    default: {} as WidgetNotificationBannerData,
  },
});

const ctaData: CTAData = {
  id: props.bannerData.name,
  type: props.bannerData.ctaType,
  label: props.bannerData.ctaLabel,
  uri: props.bannerData.ctaLink,
  btnOrientation: 'center',
  target: props.bannerData.ctaLinkTarget,
  typeHandle: BlockTypeHandle.CTA,
};
</script>
